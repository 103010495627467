.leaflet-container {
  height: 400px;
  width: 100%;
}

.card {
  border: 1px solid $blue;
  .card-header {
    white-space: pre-line;
    padding: 0.75rem 1.25rem;
    background-color: $blue;
    color: $white;
    .card-header-text {
      display: inline-block;
    }
  }
}
