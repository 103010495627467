.home-page {
  .fcub-header {
    min-height: 60vmin;

    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    @media (min-width: $screen-xl-min) {
      background-position: bottom;
    }
    .fcub-header-text {
      border-radius: 0.5rem;
      min-height: 35vmin;
      margin: 15vmin 0 5vmin;

      padding: 2vmin;
      @media (max-width: $screen-xs-max) {
        margin-top: 15vmin;
      }
      .fcub-title {
        font-weight: 700;
        color: $blue;
        font-size: 2.8rem;
        margin-bottom: 1vmin;
        @media (max-width: $screen-xs-max) {
          font-size: 2rem;
          margin-bottom: 1vmin;
        }
        @media (min-width: $screen-lg-min) {
          font-size: 3.5rem;
        }
      }
      .fcub-subtitle {
        font-weight: 400;
        color: $darkgrey;
        margin-bottom: 5vmin;
        @media (max-width: $screen-xs-max) {
          font-size: 1.3rem;
          margin-bottom: 5vmin;
        }
      }
      .hashtag-wrapper > a {
        border-radius: 15px;
        background-color: $blue;
        margin: 0 auto;
        padding: 1.2rem 0.8rem;
        @media (min-width: $screen-md-min) {
          max-width: 40%;
        }
        // &:hover {
        //   background-color: darkblue;
        //   color: $white;
        // }
        font-weight: 600;
        font-size: 1.6rem;
        color: white;
      }
    }
  }
  .iconscience {
    transform: rotate(45deg);
  }

  hr {
    border: 2px solid $blue;
    width: 3rem;
    margin: 0.3rem 0 2rem;
  }
}
