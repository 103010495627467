@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

* {
  font-family: "Lato", sans-serif;
}

@import "../shared.scss";
@import "../Stations/Stations.scss";
@import "../Home/Home.scss";
@import "../Navbar/Navbar.scss";
@import "../Footer/Footer.scss";
@import "../charts/PmChart.scss";
@import "../CommonComponents/Riquadro.scss";
@import "../CommonComponents/TitleLine.scss";
@import "../CommonComponents/Loading.scss";
@import "../Icon/Icon.scss";
@import "../Data/Data.scss";
