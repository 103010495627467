.icon-box {
  margin-bottom: 2rem;
  .icon {
    @media (min-width: $screen-md-min) {
      width: 50%;
    }

    margin-inline-start: auto;
    margin-inline-end: auto;

    width: 30vmin;
  }
  .icon-title {
    color: $blue;
    font-weight: 600;
  }

  a {
    text-decoration-color: $green;
    .icon-learn {
      color: $blue;
      font-weight: 400;
      &:hover {
        color: $green;
      }
    }
  }
}
